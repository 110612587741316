export enum CoverageType {
    notCovered = 1,
    covered,
    conditionallyCovered
}

export enum InvoiceStatus {
    draft = 1,
    closed,
    endorsed,
    canceled
}

export enum ClaimStatus {
    required = 1,
    canceled,
}

export enum DurationUnit {
    Hours = 1,
    Days,
    Weeks,
    Months,
    Years
}